let styles = {
  routerStyle: {
    WebkitTouchCallout: "none",
    WebkitUserSelect: "none",
    khtmlUserSelect: "none",
    MozUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    // backgroundColor: "#FDFDFD",
    width: "100%",
  },
  container: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "calc(100vh - 41px)",
  },
  content: {
    display: "flex",
    flex: 1,
    verticalAlign: "bottom",
    alignItems: "center",
    marginBottom: 50,
    minHeight: 280,
  },
  explainContent: {
    display: "flex",
    flex: 1,
    verticalAlign: "bottom",
    alignItems: "center",
    marginBottom: 50,
    width: "100%",
    maxWidth: 600,
  },
  explainText: {
    fontFamily: "Rubik",
    fontSize: 16,
    paddingBottom: 20,
  },
  explainTitle: {
    fontFamily: "Rubik",
    fontSize: 28,
    textAlign: "center",
    marginTop: 30,
  },
  content404: {
    display: "flex",
    flex: 1,
    verticalAlign: "bottom",
    alignItems: "center",
  },
  dashboardContainer: {
    // backgroundColor: "#F3F3F3",
    overflow: "hidden",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "calc(100vh - 41px)",
  },
  rightNowChart: {
    marginTop: 0,
    width: "100%",
    maxHeight: 360,
    minWidth: 300,
  },
  budgetChart: {
    marginTop: 0,
    width: "100%",
    maxHeight: 360,
    paddingTop: 60,
    overflow: "hidden",
    display: "flex",
    flex: 5,
    flexDirection: "column",
    position: "relative",
  },
  estimateBudget: {
    color: "rgb(128, 128, 128)",
    fontFamily: "Rubik",
    fontSize: 14,
    position: "relative",
    top: -10,
    paddingRight: 24,
    paddingLeft: 24,
    fontStyle: "italic",
  },
  budgetDetails: {
    display: "flex",
    flex: 1,
    minWidth: "max-content",
    textAlign: "center",
    height: 360,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  rightNowValue: {
    width: 200,
    position: "absolute",
    left: "50%",
    top: "50%",
    marginLeft: -100,
    marginTop: 5,
    fontFamily: "Rubik",
    fontSize: 39.1,
    lineHeight: "16px",
  },
  normalGasTank: {
    background: "rgb(0, 0, 0)",
    width: 22,
    height: 24,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 20,
    marginLeft: -11,
    zIndex: 2,
    mixBlendMode: "screen",
  },
  redGasTank: {
    background: "rgb(232, 33, 33)",
    width: 22,
    height: 24,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: 20,
    marginLeft: -11,
    zIndex: 2,
    mixBlendMode: "screen",
  },
  gasTank: {
    position: "absolute",
    width: 24,
    height: "auto",
    top: "50%",
    left: "50%",
    marginTop: 20,
    marginLeft: -12,
  },
  gasTankOpaque: {
    position: "absolute",
    width: 24,
    height: "auto",
    top: "50%",
    left: "50%",
    marginTop: 20,
    marginLeft: -12,
    opacity: 0.2,
  },
  budgetValue: {
    fontFamily: "Rubik",
    fontSize: 39.1,
  },
  budgetWarning: {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    paddingLeft: 10,
    top: -2,
  },
  budgetAvailable: {
    color: "#000",
    fontFamily: "Rubik",
    fontSize: 14,
    marginTop: -5,
  },
  rightNowPrefix: {
    fontSize: 16,
  },
  rightNowSmallText: {
    fontSize: 12.8,
  },
  rightNowSuffix: {
    fontSize: 16,
    marginTop: -10,
    display: "block",
  },
  pieModal: {
    width: "100%",
    maxWidth: 960,
  },
  appliancesWrapper: {
    top: 50,
    position: "absolute",
    height: 320,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: -30,
  },
  realtimeWrapper: {
    top: 50,
    position: "absolute",
    height: 310,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: 15,
  },
  comparisonWrapper: {
    top: 50,
    position: "absolute",
    height: 310,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: -25,
  },
  budgetWrapper: {
    top: 50,
    position: "absolute",
    height: 320,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: -30,
  },
  gasgaugeWrapper: {
    display: "flex",
    flexDirection: "row",
    top: 50,
    height: 320,
    width: "100%",
  },
  realtimeChart: {
    marginTop: 0,
    width: "100%",
    maxHeight: 310,
    minWidth: 300,
  },
  appliancesBox: {
    display: "flex",
    background: "pink",
    flex: "0 0 33.333333%",
  },
  appliancesPieChart: {
    display: "flex",
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  appliancesSettingsUnit: {
    marginTop: 20,
    marginBottom: 20,
  },
  mainContent: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    flex: 1,
    justifyContent: "top",
    alignItems: "top",
    position: "relative",
    backgroundColor: "rgb(243,243,243)",
    paddingTop: 0,
  },
  mainContentWizard: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    flex: 1,
    justifyContent: "top",
    alignItems: "top",
    position: "relative",
    paddingTop: 0,
    fontFamily: "Rubik",
  },
  mainContentWhite: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    flex: 1,
    justifyContent: "top",
    alignItems: "top",
    position: "relative",
    backgroundColor: "#fff",
    paddingTop: 0,
  },
  pageContent: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    flex: 1,
    justifyContent: "top",
    alignItems: "top",
    position: "relative",
    backgroundColor: "#F3F3F3",
    paddingTop: 120,
    paddingBottom: 100,
  },
  pageContentSmallPadding: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    flex: 1,
    justifyContent: "top",
    alignItems: "top",
    position: "relative",
    backgroundColor: "#F3F3F3",
    paddingTop: 0,
  },
  pageContentWhite: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    flex: 1,
    justifyContent: "top",
    alignItems: "top",
    position: "relative",
    paddingTop: 110,
    textAlign: "center",
  },
  notFound: {
    display: "flex",
    flex: 1,
    verticalAlign: "bottom",
    alignItems: "center",
    borderRight: "2px rgba(61, 120, 186, 0.5) solid",
    padding: 20,
    marginRight: 20,
    fontSize: 25,
    fontWeight: 600,
    color: "rgb(61, 120, 186)",
    opacity: 0.5,
  },
  contentText: {
    display: "flex",
    color: "#a8b3bc",
    fontFamily: "Rubik",
    fontWeight: 400,
    alignItems: "center",
    justifyContent: "center",
  },
  notFoundImage: {
    width: "260px",
  },
  smallBox: {
    maxWidth: 320,
    fontFamily: "Rubik",
    fontWeight: 400,
  },
  mediumBox: {
    maxWidth: 600,
  },
  demoBox: {
    display: "flex",
    height: "auto",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minWidth: 300,
    maxWidth: 700,
  },
  videoWrapper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%" /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
    marginBottom: 50,
  },
  videoIframe: {
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    width: "100%",
    height: "100%",
  },
  logoBox: {
    minHeight: "108px",
  },
  logoLogin: {
    width: "140px",
    height: "108px",
  },
  selectStyle: {
    color: "#808080",
    fontFamily: "Rubik",
    fontWeight: "100",
    width: "100%",
    maxWidth: 320,
    backgroundColor: "white",
    paddingTop: 2,
  },
  optionsStyle: {
    backgroundColor: "#FFFFFF",
  },
  calculatorTotal: {
    fontFamily: "Rubik",
    fontSize: 14,
  },
  warningBox: {
    backgroundColor: "#EB532D",
    color: "#fff",
    margin: 10,
    padding: 10,
    borderRadius: 5,
    fontFamily: "Rubik",
    fontSize: 14,
  },
  hidden: {
    backgroundColor: "#EB532D",
    color: "#fff",
    margin: 10,
    padding: 10,
    borderRadius: 5,
    fontFamily: "Rubik",
    fontSize: 14,
    opacity: 0,
  },
  calculatorIcon: {
    position: "absolute",
    right: 10,
    top: 10,
    cursor: "pointer",
    zIndex: 3,
    color: "rgb(128, 128, 128)",
  },
  budgetCalculatorIcon: {
    position: "absolute",
    right: 10,
    top: 10,
    cursor: "pointer",
    color: "rgb(128, 128, 128)",
  },
  alertTitle: {
    fontSize: 25,
    fontFamily: "Roboto",
    fontWeight: 600,
    color: "#182026",
    paddingTop: 0,
  },
  closeModal: {
    position: "absolute",
    top: 5,
    right: 5,
    color: "#5c7080",
    cursor: "pointer",
  },
  modalImage: {
    width: "100%",
    height: "auto",
  },
  energyRateWrapper: {
    maxWidth: 320,
    position: "relative",
  },
  budgetEnergyRateWrapper: {
    position: "relative",
  },
  tokenTextArea: {
    background: "rgba(15, 153, 96, 0.15)",
    padding: "8px 12px",
    borderRadius: "4px",
    border: "1px rgba(255,136,0, 1) dotted",
    color: "#878787",
    fontWeight: "bold",
    fontSize: "16px",
    width: "100%",
    overflow: "hidden",
    height: "48px",
    minHeight: "48px",
  },
  inputLabel: {
    position: "relative",
    top: 32,
    left: 15,
    maxWidth: 320,
    color: "#808080",
    fontFamily: "Rubik",
    fontSize: 14,
    textAlign: "left",
  },
  inputLabelSmall: {
    position: "relative",
    top: 32,
    left: 15,
    maxWidth: 70,
    color: "#808080",
    fontFamily: "Rubik",
    fontSize: 14,
    textAlign: "left",
  },
  inputLabelWizard: {
    position: "absolute",
    left: 0,
    top: -25,
    maxWidth: 320,
    color: "#808080",
    fontFamily: "Rubik",
    fontSize: 14,
    textAlign: "left",
  },
  input: {
    width: "100%",
    maxWidth: 280,
    color: "#888",
    fontFamily: "Rubik",
    fontWeight: "100",
    paddingTop: 2,
    margin: 0,
  },
  inputPostal: {
    width: "100%",
    maxWidth: 320,
    color: "#888",
    fontFamily: "Rubik",
    fontWeight: "100",
    paddingTop: 2,
    margin: 0,
    textTransform: "capitalize",
  },
  inputBudget: {
    width: "100%",
    color: "#888",
    fontFamily: "Rubik",
    fontWeight: "100",
    paddingTop: 2,
    margin: 0,
    textTransform: "uppercase",
  },
  settingsLabel: {
    position: "relative",
    maxWidth: 320,
    color: "#808080",
    fontFamily: "Rubik",
    fontSize: 14,
    textAlign: "left",
  },
  radioLabel: {
    fontFamily: "Rubik",
    color: "rgb(128,128,128)",
    fontSize: 16,
  },
  arrowIcons: {
    position: "absolute",
    right: 20,
    top: 18,
  },
  arrowIconsHelp: {
    position: "absolute",
    right: 5,
    top: 18,
  },
  doubleArrow: {
    color: "#808080",
    cursor: "pointer",
    opacity: 0.4,
  },
  infoIcon: {
    width: "100%",
    maxWidth: 320,
    position: "relative",
  },
  widgetInfoIcon: {
    top: -3,
    left: -4,
    position: "relative",
  },
  smallMenuLabel: {
    textTransform: "capitalize",
  },
  widgetsTopRightMenu: {
    position: "absolute",
    top: 18,
    right: 28,
    zIndex: 2,
  },
  widgetsTopRightMenuAppliances: {
    position: "absolute",
    top: 18,
    right: 34,
    zIndex: 2,
  },
  widgetButton: {
    position: "relative",
    padding: "0px 4px",
    width: "max-content",
  },
  budgetWidgetButton: {
    position: "relative",
    marginRight: -14,
    marginTop: -4,
  },
  gasgaugeUpdateButton: {
    textTransform: "uppercase",
  },
  applianceSettingsButton: {
    position: "absolute",
    top: -3,
    right: 50,
    color: "rgb(136, 136, 136)",
    display: "flex",
    flexDirection: "row",
  },
  smallWidgetDropdown: {
    position: "absolute",
    right: 50,
    top: -3,
  },
  roundedText: {
    position: "absolute",
    bottom: 10,
    right: 10,
    fontFamily: "Rubik",
    fontSize: "12.8px",
    fontWeight: 400,
    color: "rgb(129, 129, 129)",
    opacity: 0.5,
  },
  applianceItem: {
    cursor: "pointer",
  },
  poolRadioButtons: {
    paddingLeft: 16,
  },
  tokenHelpIcon: {
    width: "100%",
    maxWidth: 200,
    position: "relative",
    top: 10,
  },
  categoryInfoIcon: {
    marginLeft: 10,
    color: "#808080",
    cursor: "pointer",
    background: "white",
    borderRadius: 100,
  },
  formInfoIcon: {
    position: "absolute",
    right: -25,
    top: -5,
    color: "#808080",
    cursor: "pointer",
    background: "white",
    borderRadius: 100,
    zIndex: 2,
  },
  formInfoHelpIcon: {
    position: "absolute",
    right: -25,
    top: -5,
    color: "#FFFFFF",
    cursor: "pointer",
    background: "#808080",
    borderRadius: 100,
    zIndex: 2,
    padding: 3,
  },
  togglePassIcon: {
    position: "absolute",
    right: 24,
    top: 12,
    color: "#808080",
    cursor: "pointer",
    background: "white",
    borderRadius: 100,
    zIndex: 2,
  },
  switchTempBox: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    maxWidth: 110,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  profileTempBox: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    maxWidth: 110,
    textAlign: "left",
  },
  switchTempLabelLeft: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    minWidth: 30,
    color: "rgb(136, 136, 136)",
  },
  switchTempLabelRight: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    minWidth: 30,
    color: "rgb(136, 136, 136)",
  },
  switchWidgetLabelLeft: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    minWidth: 30,
    color: "rgb(136, 136, 136)",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 14,
  },
  switchWidgetLabelRight: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    minWidth: 30,
    color: "rgb(136, 136, 136)",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 14,
    paddingLeft: 10,
  },
  switchChartsBox: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    maxWidth: 104,
    justifyContent: "center",
    alignContent: "center",
    textAlign: "center",
  },
  switchChartsLabelLeft: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-start",
    minWidth: 20,
    color: "rgb(136, 136, 136)",
  },
  switchChartsLabelRight: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    minWidth: 34,
    color: "rgb(136, 136, 136)",
  },
  switch: {
    display: "flex",
    maxWidth: 44,
    fontFamily: "Rubik",
  },
  textPrimary: {
    color: "rgb(61, 120, 186)",
  },
  textNotFound: {
    fontSize: 25,
    color: "rgb(61, 120, 186, 0.5)",
  },
  button: {
    minWidth: 220,
    height: 40,
    paddingTop: 6,
    color: "#fff",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "uppercase",
  },
  buttonDisabled: {
    minWidth: 220,
    height: 40,
    paddingTop: 6,
    color: "#fff",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "uppercase",
    opacity: 0.9,
  },
  uploadButton: {
    minWidth: 200,
    height: 40,
    paddingTop: 6,
    color: "#fff",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "uppercase",
  },
  copyButton: {
    minWidth: "100%",
    height: 40,
    paddingTop: 6,
    color: "#fff",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 12.8,
    textTransform: "uppercase",
  },
  dontSeeButton: {
    width: "100%",
    maxWidth: 300,
    height: 40,
    paddingTop: 6,
    color: "#000",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "uppercase",
    border: "1px rgba(0,0,0,0.2) solid",
  },
  link: {
    fontSize: 14,
    fontFamily: "Rubik",
    color: "#4783c4",
    fontWeight: "100",
    cursor: "pointer",
  },
  passCheckContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    height: "210px",
    flexDirection: "column",
    width: "max-content",
  },
  passCheckSignupContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "left",
    height: "180px",
    flexDirection: "column",
    width: "max-content",
  },
  passCheckList: {
    color: "#a8b3bc",
    fontFamily: "Rubik",
    fontSize: 16,
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
  },
  languagesList: {
    color: "#808080",
    fontFamily: "Rubik",
    fontSize: 16,
    paddingTop: 0,
    textAlign: "left",
  },
  passCheckUl: {
    listStyle: "none",
    padding: 0,
    paddingTop: 0,
    marginBottom: 0,
  },
  passCheckIcon: {
    marginRight: 10,
    paddingTop: "6px",
    float: "left",
  },
  loadingText: {
    width: 320,
    height: 22,
    overflow: "hidden",
    fontSize: 14,
    fontFamily: "Rubik",
    fontWeight: 400,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -160,
    marginTop: 20,
    textAlign: "center",
  },
  fewSeconds: {
    width: 200,
    height: 22,
    overflow: "hidden",
    fontSize: 14,
    fontFamily: "Rubik",
    fontWeight: 400,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -100,
    marginTop: 50,
    textAlign: "center",
    color: "rgba(0,0,0,0.2)",
  },
  loadingImage: {
    height: 34,
    mixBlendMode: "color-dodge",
  },
  widgetLoadingImage: {
    height: 20,
    marginTop: 6,
    mixBlendMode: "exclusion",
    float: "right",
  },
  widgetLoadingBlock: {
    width: 27,
    hegiht: "auto",
    float: "right",
    marginTop: -2,
    position: "relative",
  },
  colorLoading: {
    background: "rgb(71 131 196)",
  },
  widgetTitleLeft: {
    display: "flex",
    flexDirection: "row",
  },
  theTitle: {
    display: "flex",
    flex: 1,
  },
  theLoading: {
    background: "white",
  },
  loadingDescription: {
    color: "rgba(0,0,0,0.3)",
    width: "100%",
    display: "block",
  },
  logoLoading: {
    width: 20,
    position: "absolute",
    left: 15,
    top: 8,
    mixBlendMode: "multiply",
    opacity: 0.7,
  },
  passTooltip: {
    backgroundColor: "white",
    borderRadius: "6px",
    border: "2px rgba(61, 120, 186, 0.2) solid",
    position: "absolute",
    left: "50%",
    marginLeft: "-140px",
    top: "-200px",
    boxShadow: "10px 10px 30px rgba(0,0,0,0.2), -10px -10px 30px rgba(0,0,0,0)",
  },

  errorTooltip: {
    backgroundColor: "#db3737",
    borderRadius: "6px",
    border: "1px rgba(219, 55, 55, 0.2) solid",
    position: "absolute",
    width: "auto",
    maxWidth: 320,
    padding: 10,
    textAlign: "center",
    margin: "0 auto",
    marginRight: 15,
    top: 70,
    color: "white",
    zIndex: 1,
    fontSize: 14,
    boxShadow: "5px 5px 15px rgba(0,0,0,0.2), -5px -5px 15px rgba(0,0,0,0)",
  },
  errorTooltipHidden: {
    display: "none",
  },
  languageToolTip: {
    height: "auto",
    width: "auto",
    borderRadius: "6px",
    border: "1px rgba(0,0,0, 0.1) solid",
    position: "absolute",
    right: 3,
    top: 40,
    zIndex: 2,
    color: "#182026",
  },
  passTooltipBig: {
    height: "210px",
    width: "280px",
    backgroundColor: "white",
    borderRadius: "6px",
    border: "2px rgba(61, 120, 186, 0.2) solid",
    position: "absolute",
    left: "50%",
    marginLeft: "-140px",
    top: "-230px",
    boxShadow: "10px 10px 30px rgba(0,0,0,0.2), -10px -10px 30px rgba(0,0,0,0)",
  },
  greyBackground: {
    background: "#F3F3F3",
  },
  fullPage: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    height: "100%",
    minHeight: "100vh",
  },
  page: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    minHeight: "calc(100vh - 41px)",
  },
  centeredPage: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "calc(100% - 40px)",
    minHeight: "calc(100vh - 41px)",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(243, 243, 243)",
  },
  dashboardPage: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    height: "calc(100% - 240px)",
    minHeight: "calc(100vh - 240px)",
  },
  navigation: {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#FDFDFD",
    zIndex: 10,
    display: "flex",
    background: "rgba(255, 255, 255, 1)",
    boxShadow: "0px 6px 16px rgba(197, 221, 240, 0.4)",
    borderBottom: "1px rgba(0,0,0,0.02) solid",
    width: "100%",
    height: "68px",
    position: "fixed",
  },
  rightBorder: {
    borderRight: "1px solid rgba(0, 0, 0, 0.08)",
    height: "initial",
  },
  navigationMobile: {
    position: "absolute",
    left: 0,
    zIndex: 5,
    width: 120,
    height: "100%",
    minHeight: 700,
    borderRight: "1px rgba(0,0,0,0.08) solid",
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#FDFDFD",
    opacity: 1,
    transition:
      "borderRight 0s ease 0.3s, width 0.3s ease 0s, opacity 0.3s ease 0.25s",
    transformOrigin: "50% 50%",
  },
  navigationHidden: {
    display: "none",
  },
  navigationLogo: {
    marginTop: 0,
    minWidth: 120,
    height: 40,
    textAlign: "left",
    position: "relative",
  },
  navigationLogoImage: {
    width: 90,
    height: 77.2,
    paddingLeft: 20,
  },
  smallWhiteLogo: {
    width: 34,
    height: "auto",
  },
  menu: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifContent: "right",
    paddingRight: "80px",
    justifyContent: "right",
  },
  mobileMenu: {
    position: "relative",
    width: "100%",
    display: "flex",
    justifContent: "right",
    paddingRight: "0px",
    justifyContent: "right",
    marginTop: 60,
  },
  navigationList: {
    listStyle: "none",
    padding: "0",
    minWidth: 120,
    fontFamily: "Rubik",
    fontWeight: 400,
    borderRight: "1px solid rgba(0, 0, 0, 0)",
    display: "flex",
  },
  navigationMobileList: {
    listStyle: "none",
    padding: "0",
    minWidth: 120,
    fontFamily: "Rubik",
    fontWeight: 400,
    borderRight: "1px solid rgba(0, 0, 0, 0)",
    display: "flex",
    flexDirection: "column",
  },

  navigationElement: {},
  navigationElementAlerts: {
    marginLeft: 0,
    marginRight: -1,
    position: "relative",
  },
  linkStyle: {
    display: "block",
    position: "relative",
    textAlign: "center",
    fontSize: 14,
    padding: 19,
    fontWeight: 400,
    fontFamily: "Rubik",
    cursor: "pointer",
  },
  subLinkStyle: {
    display: "block",
    position: "relative",
    textAlign: "left",
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontFamily: "Rubik",
  },
  languageLinkStyle: {
    display: "block",
    position: "relative",
    textAlign: "left",
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
    fontFamily: "Rubik",
    cursor: "pointer",
    fontWeight: 400,
  },
  setupSubLinkStyle: {
    display: "block",
    position: "relative",
    textAlign: "left",
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontFamily: "Rubik",
    cursor: "pointer",
  },
  setupSubLinkStyleNoDevice: {
    display: "block",
    position: "relative",
    textAlign: "left",
    fontSize: 12.8,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: 400,
    fontFamily: "Rubik",
    cursor: "pointer",
  },
  subMenu: {
    position: "absolute",
    zIndex: 10,
    left: 119,
    top: 0,
    padding: "10px 20px 10px 20px",
    backgroundColor: "#FDFDFD",
    border: "1px rgba(0,0,0,0.05) solid",
    borderLeft: "1px rgba(0,0,0,0.1) solid",
    boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    listStyle: "none",
  },
  setupNav: {
    textTransform: "capitalize",
    cursor: "pointer",
  },
  languagesSubMenu: {
    position: "absolute",
    zIndex: 2,
    left: 119,
    top: 434,
    width: "auto",
    minWidth: 120,
    padding: 20,
    paddingLeft: 0,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#FDFDFD",
    border: "1px rgba(0,0,0,0.05) solid",
    borderLeft: "1px rgba(0,0,0,0.1) solid",
    boxShadow: "1px 1px 2px rgba(0,0,0,0.3)",
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    listStyle: "none",
  },
  flag: {
    position: "absolute",
    left: 15,
    top: 9,
    width: 18,
    height: 18,
  },
  flagDropDown: {
    position: "absolute",
    left: -5,
    top: 9,
    width: 18,
    height: 18,
  },
  flagImage: {
    maxWidth: 20,
    maxHeight: 20,
  },
  linkText: {
    paddingTop: 5,
    letterSpacing: 0.5,
  },
  hamburger: {
    position: "fixed",
    display: "flex",
    top: 10,
    left: 10,
    zIndex: 4,
    cursor: "pointer",
    backgroundColor: "#4883C5",
    borderRadius: 100,
    height: 40,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease 0.0s",
  },
  closeMenu: {
    position: "fixed",
    display: "flex",
    top: 10,
    left: 130,
    zIndex: 4,
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,0.7)",
    borderRadius: 100,
    height: 40,
    width: 40,
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease 0s",
  },
  backDrop: {
    background: "#000000",
    backDropFilter: "blur(10px)",
    opacity: 0.5,
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: 3,
    transition: "all 0.3s ease 0s",
  },
  noBackDrop: {
    opacity: 0,
    transition: "all 0.3s ease 0.3s",
  },
  info: {
    minHeight: 700,
  },
  title: {
    fontFamily: "Rubik",
    fontSize: 25,
  },
  infoText: {
    fontFamily: "Rubik",
    fontSize: 16,
  },
  batteryIcon: {
    transform: "rotate(90deg)",
  },
  weather: {
    width: "100%",
    height: 200,
    textAlign: "left",
    backgroundColor: "#ffffff",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    boxShadow: "0px 0px 20px -5px rgba(0,0,0,0.1)",
    zIndex: 1,
  },
  headerNoWeather: {
    width: "100%",
    height: 90,
    textAlign: "left",
    backgroundColor: "#ffffff",
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    boxShadow: "0px 0px 20px -5px rgba(0,0,0,0.1)",
    zIndex: 1,
  },
  deviceInfoBar: { position: "relative" },
  deviceStatus: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    right: 24,
    top: 32,
  },
  deviceStatusOnMobile: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    right: 24,
    top: 19,
  },
  weatherSignal: {
    paddingRight: 10,
    position: "relative",
  },
  weatherContainer: {
    maxWidth: 1024,
  },
  weatherBox: {
    display: "flex",
    fontFamily: "Rubik",
    fontSize: 20,
    height: 128,
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  weatherBoxTemp: {
    display: "flex",
    fontFamily: "Rubik",
    fontSize: 20,
    height: 148,
    paddingLeft: 20,
    alignItems: "center",
    justifyContent: "left",
  },
  weatherTemperature: {
    fontFamily: "Rubik",
    fontSize: 39.1,
    textAlign: "left",
    lineHeight: "20px",
  },
  mainWeatherStat: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    fontFamily: "Rubik",
    fontSize: 39.1,
    textAlign: "center",
    lineHeight: "10px",
    textTransform: "capitalize",
    minWidth: 120,
  },
  weatherStat: {
    fontFamily: "Rubik",
    fontSize: 39.1,
    textAlign: "center",
    lineHeight: "10px",
    textTransform: "capitalize",
  },
  weatherStatTemp: {
    textAlign: "center",
    lineHeight: "10px",
    textTransform: "capitalize",
    display: "flex",
    flex: 1,
    fontFamily: "Rubik",
    fontSize: "20px",
    height: "48px",
    alignItems: "baseline",
    justifyContent: "left",
    flexDirection: "column",
    paddingLeft: 20,
  },
  temperatureStyle: {
    fontSize: 39.1,
  },
  weatherDescription: {
    fontFamily: "Rubik",
    letterSpacing: 0.5,
    color: "#818181",
    fontSize: 12.8,
  },
  weatherWindDirection: {
    fontFamily: "Rubik",
    letterSpacing: 0.5,
    color: "#818181",
    fontSize: 16,
  },
  weatherMainDescription: {
    display: "flex",
    flex: 1,
    fontFamily: "Rubik",
    letterSpacing: 0.5,
    color: "#818181",
    fontSize: 12.8,
    lineHeight: "16px",
    paddingTop: 23,
    textAlign: "left",
  },
  weatherUnit: {
    paddingLeft: 4,
    fontSize: 16,
    textTransform: "none",
  },
  weatherIcon: {
    display: "flex",
    flex: 1,
    fontFamily: "Rubik",
    fontSize: 20,
    height: 148,
    alignItems: "center",
    justifyContent: "flex-end",
  },
  weatherImage: {
    height: 40,
    width: 40,
    opacity: 1,
  },
  deviceName: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 31.3,
    paddingTop: 26,
    paddingLeft: 20,
  },
  deviceNameOnMobile: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 25,
    paddingTop: 18,
    paddingLeft: 60,
    paddingBottom: 20,
  },
  widgetBorderRight: {
    height: 360,
    borderBottom: "1px #d8d8d8 solid",
    borderRight: "1px #d8d8d8 solid",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.1)",
  },
  widgetBorderLeft: {
    height: 360,
    borderBottom: "1px #d8d8d8 solid",
    borderLeft: "1px #d8d8d8 solid",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.1)",
  },
  widgetBorderBottom: {
    height: 360,
    borderBottom: "1px #d8d8d8 solid",
    boxShadow: "inset 0 0 5px rgba(0,0,0,0.1)",
  },
  widgetNoBorder: {
    height: 360,
  },
  widgetMessage: {
    fontFamily: "Rubik",
    fontSize: 20,
    fontStyle: "oblique",
    color: "rgb(189, 189, 189)",
    padding: 20,
  },
  widgetClickableMessage: {
    fontFamily: "Rubik",
    fontSize: 20,
    fontStyle: "oblique",
    color: "rgb(189, 189, 189)",
    cursor: "pointer",
  },
  pageTitle: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  pageTitleOnMobile: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  updateFwButton: {
    position: "absolute",
    top: -3,
    right: 0,
  },
  badgeSuccessText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    position: "absolute",
    right: 0,
    top: 2,
    color: "#0F9960",
    fontSize: 12,
    textAlign: "right",
    padding: 0,
    paddingHorizontal: 10,
    textTransform: "capitalize",
  },
  badgeWarningText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    position: "absolute",
    right: 0,
    top: 2,
    color: "#D9822B",
    fontSize: 12,
    textAlign: "right",
    padding: 0,
    paddingHorizontal: 10,
    textTransform: "capitalize",
  },
  badgeDangerText: {
    fontFamily: "Rubik",
    fontWeight: 400,
    position: "absolute",
    right: 0,
    top: 2,
    color: "#DB3737",
    fontSize: 12,
    textAlign: "right",
    padding: 0,
    paddingHorizontal: 10,
    textTransform: "capitalize",
  },
  settingsCategory: {
    fontSize: 18,
    padding: 15,
    paddingBottom: 5,
    paddingLeft: 0,
    display: "block",
    borderBottom: "1px rgba(0,0,0,0.05) solid",
    color: "#888",
    fontWeight: 400,
  },
  helpCategory: {
    position: "relative",
    fontSize: 20,
    padding: 25,
    paddingBottom: 5,
    paddingLeft: 0,
    display: "block",
    borderBottom: "1px rgba(0,0,0,0.05) solid",
    color: "#888",
    fontWeight: 400,
    cursor: "pointer",
    textAlign: "left",
    maxWidth: 500,
  },
  helpQuestion: {
    paddingLeft: 10,
    paddingRight: 10,
    display: "block",
  },
  profileCategory: {
    fontSize: 18,
    padding: 15,
    paddingBottom: 5,
    paddingLeft: 0,
    display: "block",
    borderBottom: "1px rgba(0,0,0,0.05) solid",
    color: "#888",
    fontWeight: 400,
    cursor: "pointer",
  },
  deviceSettingsInfoPanel: {
    backgroundColor: "white",
    borderRadius: 10,
    border: "1px #e5e5e5 solid",
    padding: "20px",
    color: "#a1a1a1",
    paddingTop: "20px",
    margin: "0 auto",
    marginTop: 40,
    maxWidth: 300,
    textAlign: "right",
    position: "relative",
  },
  deleteBtn: {
    position: "absolute",
    right: 0,
  },
  slider: {
    width: "100%",
    maxWidth: 200,
  },
  deviceSettingsUploadPanel: {
    backgroundColor: "white",
    fontWeight: 400,
    borderRadius: 10,
    border: "1px #e5e5e5 solid",
    padding: "10px",
    color: "#a1a1a1",
    paddingTop: "20px",
    paddingBottom: "20px",
    maxWidth: 300,
    height: 230,
    margin: "0 auto",
    marginTop: 40,
    marginBottom: 40,
    overflow: "hidden",
  },
  uploadedImage: {
    height: 120,
    position: "relative",
    paddingTop: 10,
    textAlign: "center",
  },
  uploadedImageStyle: {
    height: 120,
    width: "auto",
    display: "block",
    margin: "0 auto",
  },
  uploadedImageStyleHidden: {
    height: 120,
    width: "auto",
    display: "block",
    margin: "0 auto",
    opacity: 0,
  },
  imageLink: {
    position: "relative",
  },
  deleteMeterImage: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  deviceSettingsElement: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "10px",
    textAlign: "left",
  },
  deviceSettingsLabel: {
    flex: 1,
    textAlign: "right",
  },
  deviceSettingsLabelStyle: {
    fontWeight: 400,
    paddingRight: "5px",
  },
  deviceSettingsValueStyle: {
    flex: 1,
    paddingLeft: "5px",
    position: "relative",
  },
  widgetTitle: {
    position: "absolute",
    padding: 15,
    fontFamily: "Rubik",
    fontWeight: 400,
    color: "#808080",
    fontSize: 20,
    textAlign: "left",
    zIndex: 1,
  },
  alertsCount: {
    position: "absolute",
    right: 38,
    top: 10,
    backgroundColor: "#DB3737",
    color: "white",
    width: 16,
    height: 16,
    borderRadius: 100,
    textAlign: "center",
    paddingTop: 1,
    fontSize: 10.24,
    fontWeight: 400,
    zIndex: 1,
  },
  alertsList: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 60,
    marginBottom: 60,
    marginRight: 40,
    marginLeft: 0,
    flexDirection: "column",
  },
  noNotifications: {
    display: "flex",
    marginLeft: 40,
    flex: "1 1 0%",
    verticalAlign: "bottom",
    alignItems: "center",
    fontSize: 39.1,
    fontWeight: 600,
    textTransform: "uppercase",
    backgroundColor: "rgba(0,0,0,0.2)",
    color: "transparent",
    textShadow: "1px 1px 3px rgba(255,255,255,0.5)",
    backgroundClip: "text",
    MozBackgroundClip: "text",
    WebkitBackgroundClip: "text",
  },
  alertIcon: {
    position: "absolute",
    left: 5,
    top: 0,
  },
  alertsDeleteBtn: {
    position: "absolute",
    right: 2,
    top: 2,
  },
  redAlertBox: {
    position: "relative",
    textAlign: "left",
    backgroundColor: "#fff",
    borderLeft: "5px #DB3737 solid",
    padding: 0,
    paddingTop: 15,
    marginTop: 40,
    marginLeft: 20,
    borderRadius: 6,
    boxShadow: "0px 0px 8px 2px rgba(16,22,26,.1)",
  },
  yellowAlertBox: {
    position: "relative",
    textAlign: "left",
    backgroundColor: "#fff",
    borderLeft: "5px #ff7240 solid",
    padding: 0,
    paddingTop: 15,
    marginTop: 40,
    marginLeft: 20,
    borderRadius: 6,
    boxShadow: "0px 0px 8px 2px rgba(16,22,26,.1)",
  },
  greenAlertBox: {
    position: "relative",
    textAlign: "left",
    backgroundColor: "#fff",
    borderLeft: "5px #0F9960 solid",
    padding: 0,
    paddingTop: 15,
    marginTop: 40,
    marginLeft: 20,
    borderRadius: 6,
    boxShadow: "0px 0px 8px 2px rgba(16,22,26,.1)",
  },
  alertHeader: {
    fontFamily: "Rubik",
    color: "rgb(0, 0, 0)",
    fontSize: 20,
    fontWeight: "400",
    lineHeight: "24px",
    paddingLeft: 13,
    paddingRight: 13,
    paddingTop: 0,
    marginTop: -5,
    paddingBottom: 14,
    display: "block",
  },
  alertBody: {
    fontFamily: "Rubik",
    color: "rgb(136, 136, 136)",
    fontSize: 16,
    padding: 15,
    paddingBottom: 0,
    maxHeight: 134,
    overflow: "hidden",
  },
  alertsToggle: {
    textAlign: "center",
    backgroundColor: "#fff",
    color: "#808080",
    fontSize: 12.8,
    lineHeight: "15.36px",
    cursor: "pointer",
    height: 50,
    fontFamily: "Rubik",
    fontWeight: 400,
    borderRadius: 6,
    paddingTop: 20,
  },
  helpList: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    flexDirection: "column",
    marginBottom: 60,
    marginTop: 60,
  },
  helpBlock: {
    maxWidth: 500,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20,
    textAlign: "left",
  },
  languageDropDown: {
    display: "flex",
    position: "absolute",
    right: 10,
    top: 10,
    width: "100%",
    justifyContent: "flex-end",
  },
  languageDropDownButton: {
    width: "auto",
    justifyContent: "center",
    minWidth: 132,
  },
  footerLoading: {
    display: "flex",
    width: "100%",
    verticalAlign: "bottom",
    height: "auto",
    borderTop: "1px #e5e5e5 solid",
    backgroundColor: "white",
    zIndex: 6,
  },
  footer: {
    display: "flex",
    width: "100%",
    verticalAlign: "bottom",
    height: "40px",
    borderTop: "1px #e5e5e5 solid",
    backgroundColor: "white",
    zIndex: 6,
    marginTop: 0,
  },
  footerContainer: {
    position: "sticky",
    left: "0",
    bottom: "0",
    width: "100%",
  },
  footerText: {
    fontFamily: "Rubik",
    fontWeight: 500,
    fontSize: 12.8,
    color: "#bdbdbd",
    paddingTop: 0,
    maxHeight: 20,
    lineHeight: "40px",
    textTransform: "uppercase",
  },
  footerLinksUl: {
    margin: 0,
    padding: 0,
    listStyle: "none",
  },
  footerLinksLi: {
    float: "left",
    paddingRight: 14,
    cursor: "pointer",
  },
  footerLink: {
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 12.8,
    color: "#bdbdbd",
    paddingTop: 0,
    maxHeight: 20,
    lineHeight: "40px",
    textTransform: "uppercase",
  },
  explainImage: {
    width: "100%",
    maxWidth: 800,
    padding: 10,
  },
  explainImageDiv: {
    height: 249.23,
  },
  // WIZARD STYLES
  wizSpacing: {
    marginTop: -1,
    marginBottom: 73,
  },
  wizardImage: {
    width: "100%",
    maxWidth: 320,
    padding: 0,
  },
  macImage: {
    width: "100%",
    maxWidth: 450,
    padding: 0,
    marginTop: 20,
  },
  wizardImageSmall: {
    maxWidth: 430,
    padding: 20,
    paddingTop: 60,
  },
  wizardImageMedium: {
    width: "100%",
    padding: 0,
  },
  wizardStartButton: {
    textTransform: "uppercase",
    width: "100%",
    textAlign: "center",
    fontFamily: "Rubik",
    fontWeight: "400",
    margin: "auto",
  },
  deleteButton: {
    textTransform: "uppercase",
    width: "100%",
    textAlign: "center",
    fontFamily: "Rubik",
    fontSize: 14,
    fontWeight: "400",
    maxWidth: 300,
  },
  wizardTitle: {
    fontWeight: 400,
    color: "#212529",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 25,
    opacity: 1,
    textTransform: "uppercase",
    paddingBottom: 20,
    paddingTop: 20,
  },
  wizardList: {
    backgroundColor: "#afafaf",
    paddingTop: 6,
    paddingLeft: 0,
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    borderRadius: "100%",
    width: 35,
    height: 35,
    position: "absolute",
    left: -4,
    marginTop: -4,
  },
  wizardUl: {
    listStyle: "none",
  },
  wizardLi: {
    paddingBottom: 20,
    width: "100%",
    flexDirection: "row",
    textAlign: "left",
    fontFamily: "Rubik",
    fontWeight: 200,
  },
  wizardSmallDiv: {
    maxWidth: 200,
    margin: "0 auto",
  },
  wizardImageDiv: {
    margin: "0 auto",
    height: 320,
  },
  requirementsList: {
    listStyle: "none",
    marginLeft: "-40px",
    marginBottom: 0,
  },
  wizardPrint: {
    position: "relative",
  },
  wizardNote: {
    textAlign: "left",
    backgroundColor: "rgba(219, 136, 54, 0.06)",
    padding: 25,
    overflow: "hidden",
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 4,
    boxShadow: "0px -1px 4px rgba(0,0,0,0.2)",
  },
  wizardCentered: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    height: 320,
    marginLeft: -50,
  },
  wizardImg: {
    marginTop: 40,
    marginBottom: 40,
    maxWidth: 320,
    height: "auto",
  },
  wizardStepWrapper: {
    borderColor: "white",
    borderWidth: 2,
    height: 335,
    width: 335,
    position: "relative",
  },
  wizardStepContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  wizardStepDot: {
    position: "absolute",
    top: 4,
    left: 5,
    width: 30,
    height: 30,
    padding: 4,
    lineHeight: "18px",
    textAlign: "center",
    backgroundColor: "#0F9960",
    borderRadius: "100%",
    border: "2px #ffffff solid",
  },
  wizardStepText: {
    fontSize: 14,
    color: "#ffffff",
    fontWeight: "bold",
  },
  wizardStepDesc: {
    position: "absolute",
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.4)",
    padding: 2,
    width: "auto",
    minWidth: "100%",
  },
  wizardStepDescText: {
    fontSize: 14,
    color: "#ffffff",
    lineHeight: "0px",
    fontFamily: "Rubik",
    fontWeight: 400,
    padding: 4,
  },
  wizardNavActive: {
    color: "#0f9960",
    cursor: "pointer",
    fontSize: 50,
    lineHeight: 1,
    margin: "0 2px",
    opacity: 1,
    textShadow: "0 0px 8px",
    transition: "opacity 1s ease, text-shadow 1s ease",
    willChange: "opacity, text-shadow",
  },
  addSpaceFromTop: {
    marginTop: 100,
  },
  wizardNavInactive: {
    color: "#0f9960",
    cursor: "pointer",
    fontSize: 50,
    lineHeight: 1,
    margin: "0 2px",
    opacity: 0.3,
    textShadow: "none",
    transition: "opacity 1s ease, text-shadow 1s ease",
    willChange: "opacity, text-shadow",
  },
  wizardDialog: {
    backgroundColor: "#fff",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 24,
    marginRight: 24,
  },
  wizardDialogContent: {
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 10,
    lineHeight: "22px",
  },
  wizardDialogText: {
    fontSize: 16,
    color: "rgba(0,0,0,0.7)",
  },
  firstStep: {
    maxWidth: 700,
    margin: "0 auto",
    backgroundColor: "#F3F3F3",
    paddingTop: 5,
  },
  dialogUl: {
    marginLeft: -20,
  },
  wizardContainerSmall: {
    margin: "0 auto",
  },
  wizardContainer: {
    margin: "0 auto",
    backgroundColor: "#F3F3F3",
  },
  stepContent: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
  },
  buttonNext: {
    minWidth: 100,
    paddingLeft: 25,
  },
  buttonBack: {
    minWidth: 100,
    paddingRight: 25,
  },
  wizardWarning: {
    fontFamily: "Rubik",
    fontWeight: 400,
    color: "#D9822B",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "rgba(219, 136, 54, 0.06)",
    padding: 25,
    overflow: "hidden",
    marginTop: 30,
    marginBottom: 30,
    borderRadius: 4,
    boxShadow: "0px -1px 4px rgba(0,0,0,0.2)",
    position: "relative",
  },
  wizardInfo: {
    fontFamily: "Rubik",
    fontWeight: 400,
    color: "#9f9f9f",
    fontSize: 16,
    textAlign: "center",
    position: "relative",
  },
  wizardSuccess: {
    color: "#0f9960",
    fontFamily: "Rubik",
    fontWeight: 400,
    fontSize: 16,
    textAlign: "center",
  },
  wizardInstr: {
    color: "#9f9f9f",
    fontFamily: "Rubik",
    fontWeight: 200,
    fontSize: 16,
    textAlign: "center",
  },
  wizardFinalRow: {
    borderBottom: "dotted 2px rgba(0,0,0,0.1)",
    paddingTop: 4,
    paddingBottom: 4,
  },
  wizardButtonCancel: {
    width: "100%",
    textAlign: "center",
    maxWidth: 100,
  },
};

export default styles;
