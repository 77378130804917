import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./translations/en.json";

var currentLanguage = localStorage.getItem("lang");

i18n.use(initReactI18next).init({
  resources: {
    en,
  },
  lng: currentLanguage,
  fallbackLng: "en",
  // debug: process.env.NODE_ENV !== "production",
  ns: ["translation"],
  defaultNS: "translation",
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
});
export default i18n;
