import {
  SAVE_CUSTOM_RATE_PLAN,
  SAVE_SELECTED_RATES,
  SAVE_SELECTED_CHARGES,
  RATE_PLAN_SAVED,
} from "../actions/types";

const INITIAL_STATE = {};

let ratePlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_CUSTOM_RATE_PLAN:
      return { ...state, customRatePlan: action.payload };
    case SAVE_SELECTED_RATES:
      return { ...state, selectedRates: action.payload };
    case SAVE_SELECTED_CHARGES:
      return { ...state, selectedCharges: action.payload };
    case RATE_PLAN_SAVED:
      return { ...state, ratePlanSaved: action.payload };
    default:
      return state;
  }
};

export default ratePlanReducer;
