import {
  SAVE_HELP,
  SAVE_ABOUT,
  SAVE_TERMS,
  SAVE_TIPS,
  SAVE_NOTIFICATIONS,
  SAVE_PRIVACY,
  SAVE_EXPLAIN,
} from "../actions/types";

const INITIAL_STATE = {};

let TextReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVE_HELP:
      return { ...state, help: action.payload };
    case SAVE_ABOUT:
      return { ...state, about: action.payload };
    case SAVE_TERMS:
      return { ...state, terms: action.payload };
    case SAVE_TIPS:
      return { ...state, tips: action.payload };
    case SAVE_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case SAVE_PRIVACY:
      return { ...state, privacy: action.payload };
    case SAVE_EXPLAIN:
      return { ...state, explain: action.payload };
    default:
      return state;
  }
};
export default TextReducer;
