export const SIGN_IN = "SIGN_IN";
export const LOGIN_USERID = "LOGIN_USERID";
export const SIGN_OUT = "SIGN_OUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CLEAN_FORGOT_PASSWORD = "CLEAN_FORGOT_PASSWORD";
export const VALID_INVITE = "VALID_INVITE";
export const CHANGE_PASS = "CHANGE_PASS";
export const PASSWORD_CHECK = "PASSWORD_CHECK";
export const VALID_PASS_RESET = "VALID_PASS_RESET";
export const VALID_LOGIN_CREDENTIALS = "VALID_LOGIN_CREDENTIALS";
export const VALID_EMAIL_ADDRESS = "VALID_EMAIL_ADDRESS";
export const VALID_SIGNUP = "VALID_SIGNUP";
export const ACCOUNT_AVAILABLE = "ACCOUNT_AVAILABLE";
export const CLEAR_SIGNUP = "CLEAR_SIGNUP";
export const SAVE_CAPTCHA = "SAVE_CAPTCHA";
export const SET_SERVER_ERROR = "SET_SERVER_ERROR";
export const SAVE_USERS = "SAVE_USERS";
export const SAVE_COUNTRIES = "SAVE_COUNTRIES";
export const SAVE_METERS = "SAVE_METERS";
export const SAVE_DEFAULT_METER = "SAVE_DEFAULT_METER";
export const SAVE_CURRENT_METER = "SAVE_CURRENT_METER";
export const SAVE_CURRENT_METER_DATA = "SAVE_CURRENT_METER_DATA";
export const SAVE_ADMINISTRATIVE_AREAS = "SAVE_ADMINISTRATIVE_AREAS";
export const SAVE_RIGHT_NOW = "SAVE_RIGHT_NOW";
export const SAVE_RIGHT_NOW_CURRENT_UNIT = "SAVE_RIGHT_NOW_CURRENT_UNIT";
export const SAVE_APPLIANCES = "SAVE_APPLIANCES";
export const SAVE_APPLIANCES_RANGES = "SAVE_APPLIANCES_RANGES";
export const SAVE_APPLIANCES_CURRENT_UNIT = "SAVE_APPLIANCES_CURRENT_UNIT";
export const SAVE_REALTIME = "SAVE_REALTIME";
export const SAVE_REALTIME_CURRENT_UNIT = "SAVE_REALTIME_CURRENT_UNIT";
export const SAVE_BUDGET = "SAVE_BUDGET";
export const SAVE_COMPARISON = "SAVE_COMPARISON";
export const SAVE_COMPARISON_CURRENT_UNIT = "SAVE_COMPARISON_CURRENT_UNIT";
export const SAVE_HISTORY = "SAVE_HISTORY";
export const SAVE_HISTORY_CURRENT_UNIT = "SAVE_HISTORY_CURRENT_UNIT";
export const SAVE_HISTORY_CURRENT_TYPE = "SAVE_HISTORY_CURRENT_TYPE";
export const SAVE_HAS_METER_IMAGE = "SAVE_HAS_METER_IMAGE";
export const VALID_METER_SETTINGS = "VALID_METER_SETTINGS";
export const SAVE_POSTAL_CODE_MAX = "SAVE_POSTAL_CODE_MAX";
export const METER_SETTINGS_SAVED = "METER_SETTINGS_SAVED";
export const VALID_PROFILE_SETTINGS = "VALID_PROFILE_SETTINGS";
export const PROFILE_SETTINGS_SAVED = "PROFILE_SETTINGS_SAVED";
export const UTILITY_SETTINGS_SAVED = "UTILITY_SETTINGS_SAVED";
export const RATE_PLAN_SETTINGS_SAVED = "RATE_PLAN_SETTINGS_SAVED";
export const TOKEN_COPIED = "TOKEN_COPIED";
export const TOKEN_GENERATED = "TOKEN_GENERATED";
export const SAVE_ALERTS = "SAVE_ALERTS";
export const SAVE_HELP = "SAVE_HELP";
export const SAVE_ABOUT = "SAVE_ABOUT";
export const SAVE_TERMS = "SAVE_TERMS";
export const SAVE_TIPS = "SAVE_TIPS";
export const SAVE_NOTIFICATIONS = "SAVE_NOTIFICATIONS";
export const SAVE_PRIVACY = "SAVE_PRIVACY";
export const SAVE_EXPLAIN = "SAVE_EXPLAIN";
export const SAVE_APPLIANCES_SETTINGS = "SAVE_APPLIANCES_SETTINGS";
export const SAVE_APPLIANCES_DRILLDOWN = "SAVE_APPLIANCES_DRILLDOWN";
export const LOADING_REALTIME_CHART = "LOADING_REALTIME_CHART";
export const LOADING_HISTORY_CHART = "LOADING_HISTORY_CHART";
export const SAVE_WINDOW_WIDTH = "SAVE_WINDOW_WIDTH";
export const SAVE_BUDGET_SETTINGS = "SAVE_BUDGET_SETTINGS";
export const SET_INFO = "SET_INFO";
export const SAVE_CC = "SAVE_CC";
export const SAVE_TEMP_CC = "SAVE_TEMP_CC";
export const SAVE_MORE_THAN_ONE_CC = "SAVE_MORE_THAN_ONE_CC";
export const SAVE_MORE_THAN_ONE_CC_LIST = "SAVE_MORE_THAN_ONE_CC_LIST";
export const SAVE_PREVIOUS_READING = "SAVE_PREVIOUS_READING";
export const SAVE_PREVIOUS_UPDATE = "SAVE_PREVIOUS_UPDATE";
export const SAVE_SENSOR = "SAVE_SENSOR";
export const SAVE_INSTALLATION_TYPE = "SAVE_INSTALLATION_TYPE";
export const SAVE_CC_TIMEOUT = "SAVE_CC_TIMEOUT";
export const SAVE_METER_CHOICE = "SAVE_METER_CHOICE";
export const SAVE_NETWORKS = "SAVE_NETWORKS";
export const SAVE_STRONGEST_NETWORK = "SAVE_STRONGEST_NETWORK";
export const SAVE_WIFI_PASSWORD = "SAVE_WIFI_PASSWORD";
export const SAVE_WIFI_CREDENTIALS = "SAVE_WIFI_CREDENTIALS";
export const SAVE_DEVICE_LOCATION = "SAVE_DEVICE_LOCATION";
export const VALID_LOCATION = "VALID_LOCATION";
export const SAVE_LOCATION = "SAVE_LOCATION";
export const SAVE_WIZARD_FINISHED = "SAVE_WIZARD_FINISHED";
export const SAVE_WIZARD_PROFILE_FINISHED = "SAVE_WIZARD_PROFILE_FINISHED";
export const SAVE_WIZARD_APPLIANCES_FINISHED =
  "SAVE_WIZARD_APPLIANCES_FINISHED";
export const SAVE_WIZARD_SETTINGS_FINISHED = "SAVE_WIZARD_SETTINGS_FINISHED";
export const SAVE_REDIRECT_WIZARD = "SAVE_REDIRECT_WIZARD";
export const VALID_CANCEL_RESET = "VALID_CANCEL_RESET";
export const VALID_DISABLE = "VALID_DISABLE";
export const SAVE_ALERT_TYPE = "SAVE_ALERT_TYPE";
export const SAVE_CLIENT_TOKEN = "SAVE_CLIENT_TOKEN";
export const UPDATE_FIRMWARE_SENT = "UPDATE_FIRMWARE_SENT";
export const METER_IMAGE_UPLOADED = "METER_IMAGE_UPLOADED";
export const PROFILE_DELETED = "PROFILE_DELETED";
export const UTILITY_DELETED = "UTILITY_DELETED";
export const RATE_PLAN_DELETED = "RATE_PLAN_DELETED";
export const DEVICE_DELETED = "DEVICE_DELETED";
export const BLOCK_ACCOUNT = "BLOCK_ACCOUNT";
export const METER_IMAGE_LOADED = "METER_IMAGE_LOADED";
export const EMAIL_TAKEN = "EMAIL_TAKEN";
export const SAVE_CALENDAR = "SAVE_CALENDAR";
export const SAVE_IS_PAGE_VISIBLE = "SAVE_IS_PAGE_VISIBLE";
export const SAVE_CUSTOM_RATE_PLAN = "SAVE_CUSTOM_RATE_PLAN";
export const SAVE_SELECTED_RATES = "SAVE_SELECTED_RATES";
export const SAVE_SELECTED_CHARGES = "SAVE_SELECTED_CHARGES";
export const SAVE_DASHBOARD_INFO = "SAVE_DASHBOARD_INFO";
export const SAVE_DEVICES_LOCATIONS = "SAVE_DEVICES_LOCATIONS";
export const SAVE_RATE_PLANS = "SAVE_RATE_PLANS";
export const SAVE_UTILITIES = "SAVE_UTILITIES";
export const SAVE_SELECTED_USERS = "SAVE_SELECTED_USERS";
export const SAVE_USER_DETAILS = "SAVE_USER_DETAILS";
export const SAVE_RATE_PLAN_DETAILS = "SAVE_RATE_PLAN_DETAILS";
export const SAVE_SELECTED_UTILITIES = "SAVE_SELECTED_UTILITIES";
export const SAVE_SELECTED_RATE_PLANS = "SAVE_SELECTED_RATE_PLANS";
export const VALID_UTILITY_SETTINGS = "VALID_UTILITY_SETTINGS";
export const SAVE_SELECTED_DEVICES = "SAVE_SELECTED_DEVICES";
export const VALID_DEVICE_SETTINGS = "VALID_DEVICE_SETTINGS";
export const SAVE_DEVICE_DETAILS = "SAVE_DEVICE_DETAILS";
export const RATE_PLAN_SAVED = "RATE_PLAN_SAVED";
export const FIRMWARE_SETTINGS_SAVED = "FIRMWARE_SETTINGS_SAVED";
export const VALID_FIRMWARE_SETTINGS = "VALID_FIRMWARE_SETTINGS";
export const SAVE_FIRMWARES = "SAVE_FIRMWARES";
export const SAVE_SELECTED_FIRMWARES = "SAVE_SELECTED_FIRMWARES";
export const FIRMWARE_DELETED = "FIRMWARE_DELETED";
export const EMAILS_SENT_SUCCESSFULLY = "EMAILS_SENT_SUCCESSFULLY";
export const SAVE_USERS_EMAILS = "SAVE_USERS_EMAILS";
