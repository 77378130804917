import {
  SIGN_IN,
  LOGIN_USERID,
  SIGN_OUT,
  FORGOT_PASSWORD,
  VALID_SIGNUP,
  CLEAN_FORGOT_PASSWORD,
  VALID_INVITE,
  CHANGE_PASS,
  VALID_PASS_RESET,
  VALID_LOGIN_CREDENTIALS,
  VALID_EMAIL_ADDRESS,
  ACCOUNT_AVAILABLE,
  SAVE_CAPTCHA,
  SET_SERVER_ERROR,
  VALID_CANCEL_RESET,
  VALID_DISABLE,
  SAVE_ALERT_TYPE,
  BLOCK_ACCOUNT,
} from "../actions/types";

const INITIAL_STATE = {};

let AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VALID_LOGIN_CREDENTIALS:
      return { ...state, isValidLoginCredentials: action.payload };
    case SIGN_IN:
      return { ...state, isSignedIn: true };
    case LOGIN_USERID:
      return { ...state, loginUserId: action.payload };
    case SIGN_OUT:
      return { ...state, isSignedIn: false };
    case FORGOT_PASSWORD:
      return { ...state, forgotPass: action.payload };
    case CLEAN_FORGOT_PASSWORD:
      return { ...state, forgotPass: false };
    case VALID_INVITE:
      return { ...state, isValidInvite: action.payload };
    case VALID_EMAIL_ADDRESS:
      return { ...state, isValidEmail: action.payload };
    case VALID_PASS_RESET:
      return { ...state, isValidPassReset: action.payload };
    case CHANGE_PASS:
      return { ...state, isChangePassOk: action.payload };
    case VALID_SIGNUP:
      return { ...state, isValidRegistration: action.payload };
    case ACCOUNT_AVAILABLE:
      return { ...state, isAccountAvailable: action.payload };
    case SAVE_CAPTCHA:
      return { ...state, savedCaptcha: action.payload };
    case SET_SERVER_ERROR:
      return { ...state, serverError: action.payload };
    case VALID_CANCEL_RESET:
      return { ...state, isValidCancelReset: action.payload };
    case VALID_DISABLE:
      return { ...state, isValidDisable: action.payload };
    case SAVE_ALERT_TYPE:
      return { ...state, alertType: action.payload };
    case BLOCK_ACCOUNT:
      return { ...state, isBlocked: action.payload };

    default:
      return state;
  }
};
export default AuthReducer;
