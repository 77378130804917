import React, { useState, Suspense } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import DefaultLogo from "../../assets/ui/logo-dashboard.svg";
import SmallWhiteLogo from "../../assets/ui/logo-white.svg";

import "../styles/App.css";
import styles from "../styles";
import useReactSimpleMatchMedia from "react-simple-matchmedia";

let Navigation = (props) => {
  const isMobileDevice = useReactSimpleMatchMedia(
    "(min-width: 0px) and (max-width: 991px)"
  );

  const [openMenu, setOpenMenu] = useState(false);
  const [path, setPath] = useState(window.location.pathname);

  const reshapeContent = (menuOpened) => {
    if (menuOpened === true) {
      const pageTitleElement = document.getElementById("pageTitle");
      if (pageTitleElement) {
        pageTitleElement.classList.add("extendPageTitle");
      }
      const containerElement = document.getElementById("container");
      if (containerElement) {
        containerElement.classList.add("extendedContent");
      }
      const weatherElement = document.getElementById("weatherBox");
      if (weatherElement) {
        weatherElement.classList.add("extendedContent");
      }
      const mainContentElement = document.getElementById("mainContent");
      if (mainContentElement) {
        mainContentElement.classList.add("extendedContent");
      }
    } else {
      const pageTitleElement = document.getElementById("pageTitle");
      if (pageTitleElement) {
        pageTitleElement.classList.remove("extendPageTitle");
      }
      const containerElement = document.getElementById("container");
      if (containerElement) {
        containerElement.classList.remove("extendedContent");
      }
      const weatherElement = document.getElementById("weatherBox");
      if (weatherElement) {
        weatherElement.classList.remove("extendedContent");
      }
      const mainContentElement = document.getElementById("mainContent");
      if (mainContentElement) {
        mainContentElement.classList.remove("extendedContent");
      }
    }
  };

  return (
    <>
      <Suspense
        fallback={
          <div style={styles.rightBorder}>
            <div
              id="navigation"
              style={
                isMobileDevice && !openMenu
                  ? styles.navigationHidden
                  : isMobileDevice && openMenu
                  ? styles.navigationMobile
                  : styles.navigation
              }
            ></div>
          </div>
        }
      >
        <div style={styles.rightBorder}>
          {path === "/dashboard" ||
          path === "/rateplans" ||
          path === "/users" ||
          path === "/devices" ||
          path === "/utilities" ||
          path === "/firmwares" ||
          path === "/emails" ? (
            <div id="mobileNavigation">
              {/* HAMBURGER MENU */}
              {isMobileDevice ? (
                <div
                  style={!openMenu ? styles.hamburger : styles.closeMenu}
                  onClick={() => {
                    document.body.scrollTop =
                      document.documentElement.scrollTop = 0;
                    reshapeContent(!openMenu);
                    setOpenMenu(!openMenu);
                  }}
                >
                  {!openMenu ? (
                    <img
                      src={SmallWhiteLogo}
                      alt="EnergyCloud"
                      style={styles.smallWhiteLogo}
                    />
                  ) : (
                    <Icon icon="cross" iconSize={18} color="white" />
                  )}
                </div>
              ) : null}
            </div>
          ) : null}
          {/* BACKDROP */}
          <div
            style={openMenu ? styles.backDrop : styles.noBackDrop}
            onClick={() => {
              reshapeContent(!openMenu);
              setOpenMenu(!openMenu);
              document.documentElement.scrollTop = 0;
            }}
            className="backDrop"
            id="backDrop"
          ></div>

          {/* NAVIGATION SIDEBAR */}
          {path === "/dashboard" ||
          path === "/rateplans" ||
          path === "/users" ||
          path === "/devices" ||
          path === "/utilities" ||
          path === "/firmwares" ||
          path === "/emails" ? (
            <div
              id="navigation"
              className=""
              style={
                isMobileDevice && !openMenu
                  ? styles.navigationHidden
                  : isMobileDevice && openMenu
                  ? styles.navigationMobile
                  : styles.navigation
              }
            >
              <div style={styles.navigationLogo}>
                <img
                  src={DefaultLogo}
                  alt="EnergyCloud"
                  style={styles.navigationLogoImage}
                />
              </div>
              <div style={isMobileDevice ? styles.mobileMenu : styles.menu}>
                <ul
                  style={
                    isMobileDevice
                      ? styles.navigationMobileList
                      : styles.navigationList
                  }
                  className={isMobileDevice ? "mobileLinks" : ""}
                >
                  <Link
                    to="/dashboard"
                    onClick={() => {
                      setPath("/dashboard");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/dashboard" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Dashboard</div>
                      </span>
                    </li>
                  </Link>
                  <Link
                    to="/users"
                    onClick={() => {
                      setPath("/users");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/users" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Users</div>
                      </span>
                    </li>
                  </Link>
                  <Link
                    to="/devices"
                    onClick={() => {
                      setPath("/devices");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/devices" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Devices</div>
                      </span>
                    </li>
                  </Link>
                  <Link
                    to="/firmwares"
                    onClick={() => {
                      setPath("/firmwares");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/firmwares" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Firmwares</div>
                      </span>
                    </li>
                  </Link>
                  <Link
                    to="/rateplans"
                    onClick={() => {
                      setPath("/rateplans");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/rateplans" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Rate plans</div>
                      </span>
                    </li>
                  </Link>
                  <Link
                    to="/utilities"
                    onClick={() => {
                      setPath("/utilities");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/utilities" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Utilities</div>
                      </span>
                    </li>
                  </Link>
                  <Link
                    to="/emails"
                    onClick={() => {
                      setPath("/emails");
                    }}
                  >
                    <li style={styles.navigationElement} className="">
                      <span
                        style={styles.linkStyle}
                        className={
                          path === "/emails" ? "navLink active" : "navLink"
                        }
                      >
                        <div style={styles.linkText}>Send Emails</div>
                      </span>
                    </li>
                  </Link>
                </ul>
              </div>
              <div className={isMobileDevice ? "logout-mobile" : "logout"}>
                <a href="/logout">
                  <span style={styles.linkStyle} disabled={true}>
                    <Icon icon="power" iconSize={24} intent="danger" />
                  </span>
                </a>
              </div>
            </div>
          ) : null}
        </div>
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    alerts: state.dashboard.alerts,
    meters: state.dashboard.meters,
    currentMeter: state.dashboard.currentMeter,
    userInfo: state.dashboard.userInfo,
    currentMeterData: state.dashboard.currentMeterData,
  };
};

Navigation = connect(mapStateToProps, {})(Navigation);

export default Navigation;
