import {
  SAVE_USERS,
  SAVE_COUNTRIES,
  SAVE_METERS,
  SAVE_DEFAULT_METER,
  SAVE_CURRENT_METER,
  SAVE_CURRENT_METER_DATA,
  SAVE_ADMINISTRATIVE_AREAS,
  SAVE_RIGHT_NOW,
  SAVE_RIGHT_NOW_CURRENT_UNIT,
  SAVE_APPLIANCES,
  SAVE_APPLIANCES_CURRENT_UNIT,
  SAVE_REALTIME,
  SAVE_REALTIME_CURRENT_UNIT,
  SAVE_BUDGET,
  SAVE_COMPARISON,
  SAVE_COMPARISON_CURRENT_UNIT,
  SAVE_HISTORY,
  SAVE_HISTORY_CURRENT_UNIT,
  SAVE_HISTORY_CURRENT_TYPE,
  SAVE_HAS_METER_IMAGE,
  VALID_METER_SETTINGS,
  SAVE_POSTAL_CODE_MAX,
  METER_SETTINGS_SAVED,
  SET_SERVER_ERROR,
  VALID_PROFILE_SETTINGS,
  VALID_UTILITY_SETTINGS,
  PROFILE_SETTINGS_SAVED,
  TOKEN_COPIED,
  SAVE_ALERTS,
  SAVE_APPLIANCES_SETTINGS,
  SAVE_APPLIANCES_DRILLDOWN,
  LOADING_REALTIME_CHART,
  SAVE_WINDOW_WIDTH,
  SAVE_BUDGET_SETTINGS,
  SAVE_CLIENT_TOKEN,
  TOKEN_GENERATED,
  UPDATE_FIRMWARE_SENT,
  METER_IMAGE_UPLOADED,
  PROFILE_DELETED,
  DEVICE_DELETED,
  METER_IMAGE_LOADED,
  EMAIL_TAKEN,
  SAVE_CALENDAR,
  LOADING_HISTORY_CHART,
  SAVE_IS_PAGE_VISIBLE,
  SAVE_APPLIANCES_RANGES,
  SAVE_DASHBOARD_INFO,
  SAVE_DEVICES_LOCATIONS,
  SAVE_UTILITIES,
  SAVE_RATE_PLANS,
  SAVE_SELECTED_USERS,
  SAVE_USER_DETAILS,
  SAVE_SELECTED_UTILITIES,
  SAVE_SELECTED_RATE_PLANS,
  SAVE_SELECTED_DEVICES,
  VALID_DEVICE_SETTINGS,
  SAVE_DEVICE_DETAILS,
  SAVE_FIRMWARES,
  FIRMWARE_SETTINGS_SAVED,
  VALID_FIRMWARE_SETTINGS,
  SAVE_SELECTED_FIRMWARES,
  FIRMWARE_DELETED,
  EMAILS_SENT_SUCCESSFULLY,
  SAVE_USERS_EMAILS,
} from "../actions/types";

const INITIAL_STATE = {};

let dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SERVER_ERROR:
      return { ...state, serverError: action.payload };
    case SAVE_USERS:
      return { ...state, users: action.payload };
    case SAVE_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case SAVE_COUNTRIES:
      return { ...state, countries: action.payload };
    case SAVE_ADMINISTRATIVE_AREAS:
      return { ...state, administrativeAreas: action.payload };
    case SAVE_METERS:
      return { ...state, meters: action.payload };
    case SAVE_DEFAULT_METER:
      return { ...state, defaultMeter: action.payload };
    case SAVE_CURRENT_METER:
      return { ...state, currentMeter: action.payload };
    case SAVE_CURRENT_METER_DATA:
      return { ...state, currentMeterData: action.payload };
    case SAVE_POSTAL_CODE_MAX:
      return { ...state, postalCodeMaxLength: action.payload };
    case SAVE_RIGHT_NOW:
      return { ...state, rightNow: action.payload };
    case SAVE_RIGHT_NOW_CURRENT_UNIT:
      return { ...state, rightNowCurrentUnit: action.payload };
    case SAVE_APPLIANCES:
      return { ...state, appliances: action.payload };
    case SAVE_APPLIANCES_CURRENT_UNIT:
      return { ...state, appliancesCurrentUnit: action.payload };
    case SAVE_APPLIANCES_DRILLDOWN:
      return { ...state, appliancesDrilldown: action.payload };
    case SAVE_REALTIME:
      return { ...state, realtime: action.payload };
    case SAVE_REALTIME_CURRENT_UNIT:
      return { ...state, realtimeCurrentUnit: action.payload };
    case SAVE_BUDGET:
      return { ...state, budget: action.payload };
    case SAVE_COMPARISON:
      return { ...state, comparison: action.payload };
    case SAVE_COMPARISON_CURRENT_UNIT:
      return { ...state, comparisonCurrentUnit: action.payload };
    case SAVE_HISTORY:
      return { ...state, history: action.payload };
    case SAVE_HISTORY_CURRENT_UNIT:
      return { ...state, historyCurrentUnit: action.payload };
    case SAVE_HISTORY_CURRENT_TYPE:
      return { ...state, historyCurrentType: action.payload };
    case SAVE_HAS_METER_IMAGE:
      return { ...state, hasMeterImage: action.payload };
    case VALID_METER_SETTINGS:
      return { ...state, isValidMeterSettings: action.payload };
    case METER_SETTINGS_SAVED:
      return { ...state, meterSettingsSaved: action.payload };
    case FIRMWARE_SETTINGS_SAVED:
      return { ...state, firmwareSettingsSaved: action.payload };
    case VALID_PROFILE_SETTINGS:
      return { ...state, isValidProfileSettings: action.payload };
    case VALID_UTILITY_SETTINGS:
      return { ...state, isValidUtilitySettings: action.payload };
    case VALID_FIRMWARE_SETTINGS:
      return { ...state, isValidFirmwareSettings: action.payload };
    case PROFILE_SETTINGS_SAVED:
      return { ...state, profileSettingsSaved: action.payload };
    case TOKEN_COPIED:
      return { ...state, isTokenCopied: action.payload };
    case SAVE_ALERTS:
      return { ...state, alerts: action.payload };
    case SAVE_APPLIANCES_SETTINGS:
      return { ...state, isValidAppliancesSettings: action.payload };
    case LOADING_REALTIME_CHART:
      return { ...state, loadingRealtime: action.payload };
    case SAVE_WINDOW_WIDTH:
      return { ...state, currentWidth: action.payload };
    case SAVE_BUDGET_SETTINGS:
      return { ...state, budgetSettingsSavedSuccessfully: action.payload };
    case SAVE_CLIENT_TOKEN:
      return { ...state, clientToken: action.payload };
    case TOKEN_GENERATED:
      return { ...state, tokenGenerated: action.payload };
    case UPDATE_FIRMWARE_SENT:
      return { ...state, updateFirmwareSet: action.payload };
    case METER_IMAGE_UPLOADED:
      return { ...state, meterImageUploaded: action.payload };
    case PROFILE_DELETED:
      return { ...state, profileDeletedSuccessfully: action.payload };
    case DEVICE_DELETED:
      return { ...state, deviceDeletedSuccessfully: action.payload };
    case METER_IMAGE_LOADED:
      return { ...state, meterImageLoaded: action.payload };
    case EMAIL_TAKEN:
      return { ...state, isEmailTaken: action.payload };
    case SAVE_CALENDAR:
      return { ...state, currentCalendarDate: action.payload };
    case LOADING_HISTORY_CHART:
      return { ...state, loadingHistory: action.payload };
    case SAVE_IS_PAGE_VISIBLE:
      return { ...state, isPageVisible: action.payload };
    case SAVE_APPLIANCES_RANGES:
      return { ...state, appliancesRanges: action.payload };
    case SAVE_DASHBOARD_INFO:
      return { ...state, dashboardInfo: action.payload };
    case SAVE_DEVICES_LOCATIONS:
      return { ...state, devicesLocations: action.payload };
    case SAVE_UTILITIES:
      return { ...state, utilities: action.payload };
    case SAVE_FIRMWARES:
      return { ...state, firmwares: action.payload };
    case FIRMWARE_DELETED:
      return { ...state, firmwareDeletedSuccessfully: action.payload };
    case SAVE_RATE_PLANS:
      return { ...state, ratePlans: action.payload };
    case SAVE_SELECTED_USERS:
      return { ...state, selectedUsers: action.payload };
    case SAVE_SELECTED_UTILITIES:
      return { ...state, selectedUtilities: action.payload };
    case SAVE_SELECTED_FIRMWARES:
      return { ...state, selectedFirmwares: action.payload };
    case SAVE_SELECTED_RATE_PLANS:
      return { ...state, selectedRateplans: action.payload };
    case SAVE_SELECTED_DEVICES:
      return { ...state, selectedDevices: action.payload };
    case VALID_DEVICE_SETTINGS:
      return { ...state, isValidDeviceSettings: action.payload };
    case SAVE_DEVICE_DETAILS:
      return { ...state, deviceDetails: action.payload };
    case EMAILS_SENT_SUCCESSFULLY:
      return { ...state, emailsSent: action.payload };
    case SAVE_USERS_EMAILS:
      return { ...state, usersEmails: action.payload };
    default:
      return state;
  }
};
export default dashboardReducer;
