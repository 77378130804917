import React, { Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import "./styles/App.css";
import styles from "./styles";
import LoadingScreen from "./screens/LoadingScreen";
import Navigation from "./ui/Navigation";
import IsUserLoggedIn from "../common/IsUserLoggedIn";

const Login = React.lazy(() => import("./screens/Login"));
const Mfa = React.lazy(() => import("./screens/Mfa"));
const ForgotPassword = React.lazy(() => import("./screens/ForgotPassword"));
const CancelReset = React.lazy(() => import("./screens/CancelReset"));
const Validate = React.lazy(() => import("./screens/Validate"));
const PassReset = React.lazy(() => import("./screens/ResetPassword"));
const Logout = React.lazy(() => import("./screens/Logout"));
const Dashboard = React.lazy(() => import("./screens/Dashboard"));
const RatePlans = React.lazy(() => import("./screens/RatePlans"));
const Users = React.lazy(() => import("./screens/Users"));
const Devices = React.lazy(() => import("./screens/Devices"));
const Utilities = React.lazy(() => import("./screens/Utilities"));
const Firmwares = React.lazy(() => import("./screens/Firmwares"));
const Emails = React.lazy(() => import("./screens/Emails"));
const Footer = React.lazy(() => import("./ui/Footer"));

const App = () => {
  const isLoggedIn = IsUserLoggedIn();

  return (
    <div>
      <BrowserRouter path={"/"} basename={"/"}>
        {!isLoggedIn ? <></> : <Navigation />}

        <div style={styles.container}>
          <div style={{ display: "flex", flex: 1, width: "100%" }}>
            <div
              style={styles.dashboardContainer}
              className="dashboardContainer"
            >
              <div style={styles.routerStyle}>
                <Suspense fallback={<LoadingScreen />}>
                  <Route path="/" exact component={Login} />
                  <Route path="/login" exact component={Login} />
                  <Route path="/mfa" exact component={Mfa} />
                  <Route
                    path="/forgotPassword"
                    exact
                    component={ForgotPassword}
                  />
                  <Route path="/validate" exact component={Validate} />
                  <Route path="/resetPassword" exact component={PassReset} />
                  <Route path="/cancelReset" exact component={CancelReset} />
                  <Route path="/dashboard" exact component={Dashboard} />
                  <Route path="/ratePlans" exact component={RatePlans} />
                  <Route path="/users" exact component={Users} />
                  <Route path="/devices:id?" exact component={Devices} />
                  <Route path="/utilities" exact component={Utilities} />
                  <Route path="/firmwares" exact component={Firmwares} />
                  <Route path="/emails" exact component={Emails} />
                  <Route path="/logout" exact component={Logout} />
                </Suspense>
              </div>
            </div>
          </div>
          <Suspense fallback={<></>}>
            <Footer />
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
