import React from "react";

class SetLanguage extends React.Component {
  componentDidMount() {
    let browserLanguage = navigator.language.substr(0, 2);
    if (
      localStorage.getItem("lang") === "null" ||
      localStorage.getItem("lang") === undefined ||
      localStorage.getItem("lang") === null
    ) {
      localStorage.setItem("lang", browserLanguage);
    }
  }
  render() {
    return null;
  }
}

export default SetLanguage;
